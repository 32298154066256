import {useEffect, useState} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import LogoHeading from './LogoHeading'
import moment from 'moment';
moment().format();

function Dashboard() {
    const dev = false;
    const protocol = 'http'

    const port = dev ?
        protocol === 'https' ? 443 : 8888
        : protocol === 'https' ? 443 : 80
    const subPath = dev ? '' : '/api';

    const [terminalReq, setTerminalReq] = useState()

    useEffect(() => {
        const interv = setInterval(loadData, 1000);
        return () => clearInterval(interv)
    }, [])

    const loadData = () => {
        fetch(protocol + '://' + window.location.hostname + ':' + port + subPath + '/get_terminal-request.php')
            .then(response => response.json())
            .then(data => {
                console.log(data);
                setTerminalReq(data)
            });
    }

    const delData = () => {
        fetch(protocol + '://' + window.location.hostname + ':' + port + subPath + '/del_terminal-request.php')
            .then(response => response.json())
            .then(data => {
                console.log('[delData] : ' + data);
            });
    }

    return (
        <div className='Dashboard bg-grey-50 text-black font-montserrat min-h-screen p-8'>
            <LogoHeading title='Dashboard' />
            <main className=''>
                { (terminalReq === undefined || terminalReq === null) ? <p className='my-12'>No data yet.</p> :
                    <div className='flex flex-col md:flex-row md:space-x-12 my-12'>
                        <div className='flex flex-col space-y-6 md:w-1/4 mb-6'>
                            <div className='flex flex-col'>
                                <span className='text-grey-600'>Terminal UUID</span>
                                <span className='text-xl'>{terminalReq.uuid}</span>
                            </div>
                            <div className='flex flex-col'>
                                <span className='text-grey-600'>Timestamp</span>
                                <span className='text-xl'>{moment.unix(terminalReq.timestamp).format("HH:mm DD.MM.YYYY")}</span>
                            </div>
                            <div className='flex flex-col'>
                                <span className='text-grey-600'>Inital</span>
                                <span className='text-xl'>{terminalReq.init ? 'True' : 'False'}</span>
                            </div>
                            <div className='flex flex-col'>
                                <span className='text-grey-600'>Position</span>
                                <span className='text-xl'>{terminalReq.pos}</span>
                            </div>
                        </div>
                        <div className='flex flex-col md:flex-grow'>
                            <span className='text-grey-600'>Karte</span>
                            <iframe className='h-96 mt-2' src={'https://maps.google.com/maps?q=' + terminalReq.pos + '&ie=UTF8&t=&z=16&iwloc=B&output=embed'} scrolling="no"></iframe>
                        </div>
                    </div>
                }
                { (terminalReq === undefined || terminalReq === null) ? '' :
                    <div>
                        <button onClick={delData}>
                            <FontAwesomeIcon icon={faTrashAlt} className='text-red-500 mr-2'/>
                            Letzte Anfrage entfernen
                        </button>
                    </div>
                }
            </main>
        </div>
    );
}

export default Dashboard;
