import './App.css';
import {useEffect, useState, useRef} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRotateLeft, faFire, faCopy, faAngleDown, faAngleUp, faTrain, faStopwatch } from '@fortawesome/free-solid-svg-icons'
import LogoHeading from './LogoHeading'

import Vehicle from './Vehicle'

import img_map              from './images/mtl_karte.png'
import img_fw_car           from './images/fw_auto_oben.png'
import img_fw_car_ghost     from './images/fw_auto_oben_ghost.png'
import img_train            from './images/train.png'
import img_station          from './images/station.png'
import img_crossing_green   from './images/crossing_green.png'
import img_crossing_red     from './images/crossing_red.png'
import img_bridge           from './images/bridge.png'


function App() {
    const dev = false;
    const protocol = 'http'

    const port = dev ?
        protocol === 'https' ? 443 : 8888
        : protocol === 'https' ? 443 : 80
    const subPath = dev ? '' : '/api';

    /* Images */
    const map = useRef(null);
    const fw_car = useRef(null)
    const fw_car_ghost = useRef(null)
    const train = useRef(null)
    const station = useRef(null)
    const crossing_green = useRef(null)
    const crossing_red = useRef(null)
    const bridge = useRef(null)

    /* Canvas */
    const canvasMap = useRef(null);
    let ctxMap;

    /* Animation settings */
    const fps = 60;
    let timeFactor = 10;

    const toggleTimeFactor = () => {
        switch (timeFactor) {
            case 1: timeFactor = 2; break;
            case 2: timeFactor = 10; break;
            case 10: timeFactor = 50; break;
            case 50: timeFactor = 1; break;
            default: timeFactor = 10;
        }
    }

    /* Entities/Vehicles */
    const entities = []
    let crossingOpen = true;
    let crossingTimes = {
        'closing': 0,
        'opening': 0,
        'fwDeparture': 0,
        'fwDuration': 0
    }

    /* Routes */
    const routeShort = [{"x":1323,"y":192},{"x":1363,"y":239},{"x":1091,"y":356},{"x":1075,"y":684},{"x":1089,"y":763},{"x":1511,"y":739,"c":crossingTimes},{"x":1509,"y":852},{"x":1536,"y":851}]
    const routeLong = [{"x":1323,"y":192},{"x":1363,"y":239},{"x":1091,"y":356},{"x":800,"y":365},{"x":708,"y":325},{"x":660,"y":348},{"x":791,"y":485},{"x":861,"y":640},{"x":843,"y":852},{"x":831,"y":888},{"x":796,"y":865},{"x":785,"y":841},{"x":788,"y":815},{"x":883,"y":805},{"x":977,"y":861},{"x":1180,"y":861},{"x":1371,"y":959},{"x":1407,"y":907},{"x":1509,"y":920},{"x":1509,"y":852},{"x":1536,"y":851}]
    const routeTrain = [{x:2120, y:710}, {x:1094, y:782, h:35*fps}, {x:-200, y:870}]

    /* Draw */
    useEffect(() => {
        ctxMap = canvasMap.current.getContext("2d");
        ctxMap.font = '14px sans-serif';
        ctxMap.textAlign = 'left';
        ctxMap.textBaseline = 'top';

        ctxTrainProgress = canvasTrainProgress.current.getContext("2d");

        window.requestAnimationFrame(animateScene);
    }, [])

    const sleep = (ms) => { let t = performance.now(); do{ } while( performance.now() - t < ms ); }

    const animateScene = async () => {
        /* Canvas Map */
        ctxMap.drawImage(map.current, 0, 0, canvasMap.current.width, canvasMap.current.height) // draws map
        ctxMap.drawImage(bridge.current, 838, 628, bridge.current.width, bridge.current.height) // draws bridge
        for (let i = 0; i < entities.length; i++) { // draws entity and the brige above if it is a train
            let entity = entities[i]
            if (entity === null) return;
            entity.move()
            const figure = entity.figureImg.current;
            let scale = 0.07
            if (entity.figureImg == train) scale = 0.5;
            //--
            ctxMap.save()
            ctxMap.translate(entity.pos.x, entity.pos.y)
            ctxMap.rotate(entity.getAngle())
            ctxMap.drawImage(figure, 0-figure.width*scale/2, 0-figure.height*scale/2, figure.width*scale, figure.height*scale)
            ctxMap.restore()
            //--
            if (entity.figureImg == train) ctxMap.drawImage(bridge.current, 838, 628, bridge.current.width, bridge.current.height)
        }
        ctxMap.drawImage(station.current, 1075, 770, station.current.width, station.current.height)
        if (crossingOpen) ctxMap.drawImage(crossing_green.current, 1575-crossing_green.current.width, 690, crossing_green.current.width, crossing_green.current.height)
        else ctxMap.drawImage(crossing_red.current, 1575-crossing_red.current.width, 690, crossing_red.current.width, crossing_red.current.height)
        //ctx.drawImage(crossing_red.current, 1575-crossing_red.current.width, 690, crossing_red.current.width, crossing_red.current.height)
        //console.log('[animateScene] crossingOpen: ' + crossingOpen)
        calculateFPSNormal();
        ctxMap.fillText(Math.round(currFps).toString(), 10, 10);
        ctxMap.fillText(Math.round(timeFactor).toString() + 'x', 10, 30);
        sleep(1000 / fps)
        window.requestAnimationFrame(animateScene);
    }

    let frames = 0;
    let startTime = performance.now();
    let currFps = 0;
    const calculateFPSNormal = () => {
        let t = performance.now();
        let dt = t - startTime;
        // if elapsed time is greater than 1s
        if( dt > 1000 ) {
            // calculate the frames drawn over the period of time
            currFps = frames * 1000 / dt;
            //console.log('fps: ' + frames * 1000 / dt);
            // and restart the values
            frames = 0;
            startTime = t;
        }
        frames++;
    }

    /* Fire-Button Action */
    const fire = () => {
        console.log('[fire] clicked.')
        //crossingOpen = !crossingOpen;
        entities.push(new Vehicle(fw_car_ghost, routeLong, 30, timeFactor, 0)); // 29.3
        entities.push(new Vehicle(fw_car, routeShort, 30, timeFactor, 0));

        crossingTimes.fwDeparture = Date.now() / 1000;
        crossingTimes.fwDuration = Math.round(256.26 / timeFactor * 100) / 100
        setCrossingTimes();
    }

    const [stations, setStations] = useState([ // duration = travel time in secs
        { 'name': 'Hanau Hauptbahnhof', 'duration': 178 }, // not right yet
        { 'name': 'Hanau West', 'duration': 131 },
        { 'name': 'Hanau Wilhelmsbad', 'duration': 145 },
        { 'name': 'Maintal Ost', 'duration': 131 },
        { 'name': 'Maintal West', 'duration': 120 },
        { 'name': 'Frankfurt Mainkur', 'duration': 207 },
        { 'name': 'Frankfurt Ost', 'duration': 156 },
        { 'name': 'Frankfurt Süd', 'duration': null }
    ])

    /* Route creation */
    const [points, setPoints] = useState([]);
    useEffect(() => {
        const ctx = canvasMap.current.getContext("2d");
        ctx.lineWidth = "10";
        ctx.lineCap = 'round';
        ctx.strokeStyle = "blue";
        ctx.drawImage(map.current, 0, 0, canvasMap.current.width, canvasMap.current.height)
        ctx.beginPath();
        for (let i = 0; i < points.length; i++) {
            if (i === 0) ctx.moveTo(points[i].x, points[i].y);
            ctx.lineTo(points[i].x, points[i].y);
            ctx.moveTo(points[i].x, points[i].y);
        }
        ctx.stroke();
    }, [points])

    const canvasMapClick = (e) => {
        let x = e.target.width / e.target.clientWidth * (e.pageX - e.target.offsetLeft);
        let y = e.target.height / e.target.clientHeight * (e.pageY - e.target.offsetTop);
        x = Math.round(x);
        y = Math.round(y);
        // console.log('x: ' + x + ', y: ' + y)
        setPoints(p => [...p, {'x': x, 'y': y}])
    }

    const drawBothRoutes = () => {
        const ctx = canvasMap.current.getContext("2d");
        ctx.lineWidth = "10";
        ctx.lineCap = 'round';
        ctx.drawImage(map.current, 0, 0, canvasMap.current.width, canvasMap.current.height)
        // short path
        ctx.strokeStyle = "green";
        ctx.beginPath();
        for (let i = 0; i < routeShort.length; i++) {
            if (i === 0) ctx.moveTo(routeShort[i].x, routeShort[i].y);
            ctx.lineTo(routeShort[i].x, routeShort[i].y);
            ctx.moveTo(routeShort[i].x, routeShort[i].y);
        }
        ctx.stroke();
        // long path
        ctx.strokeStyle = "red";
        ctx.beginPath();
        for (let i = 0; i < routeLong.length; i++) {
            if (i === 0) ctx.moveTo(routeLong[i].x, routeLong[i].y);
            ctx.lineTo(routeLong[i].x, routeLong[i].y);
            ctx.moveTo(routeLong[i].x, routeLong[i].y);
        }
        ctx.stroke();
    }
    /* Route creation end */

    /* Station Selection */
    const canvasTrainProgress = useRef(null)
    let ctxTrainProgress;

    let stationIndex = null;
    let nextStations = [];
    const staSel = (e) => {
        e.preventDefault();
        let formData = new FormData(e.target)
        stationIndex = parseInt(formData.get('station'))
        if (stationIndex === null) return;
        // console.log('[staSel] Abfahrt von ' + stations[stationIndex].name)

        let travelTime = 0; // all in secs.
        let stopsTime = 35;
        let stops = 0;
        let trainDelta = 0;
        let closingDelta = 0;
        let openingDelta = 0;
        if (stationIndex > 3) { // 3 is Maintal Ost
            // console.log('[staSel] nach rechts')
            nextStations = stations.slice(3, stationIndex+1).reverse()
            nextStations.map((s, i) => travelTime += i > 0 ? s.duration : 0)
            closingDelta = -150; // not the right time yet
            openingDelta = 60; // not the right time yet
            stops = (nextStations.length-1)
            travelTime += stops * stopsTime;
            entities.push(new Vehicle(train, [...routeTrain].reverse(), 80, timeFactor, travelTime - (stations[3].duration+35 - 32)));

            ctxTrainProgress.fillStyle = '#000'
            ctxTrainProgress.fillRect(0,0,1,100);
            ctxTrainProgress.fillStyle = '#DC2626'
            ctxTrainProgress.fillRect(0,100-100/8*(8-stationIndex-.5),1,100);
        } else {
            // console.log('[staSel] nach links')
            nextStations = stations.slice(stationIndex, 3+1)
            nextStations.map((s, i) => travelTime += i < nextStations.length-1 ? s.duration : 0)
            closingDelta = -250;
            openingDelta = -40;
            stops = (nextStations.length-2)
            travelTime += stops * stopsTime;
            entities.push(new Vehicle(train, routeTrain, 80, timeFactor, travelTime - (stations[2].duration - 36)));

            ctxTrainProgress.fillStyle = '#000'
            ctxTrainProgress.fillRect(0,0,1,100);
            ctxTrainProgress.fillStyle = '#DC2626'
            ctxTrainProgress.fillRect(0,0,1,100/8*(stationIndex+.5));
        }
        // console.log('[staSel] nächste Stationen: ' + nextStations.map(s => s.name).toString()) // incl. dep. station
        // console.log('[staSel] travelTime: ' + travelTime + 'sec') // including hold time

        // Crossing timeing
        crossingTimes.closing = Date.now() / 1000 + (travelTime+closingDelta)/timeFactor;
        crossingTimes.opening = Date.now() / 1000 + (travelTime+openingDelta)/timeFactor;
        // TODO: multiple trains? different opening/closing beha.
        setTimeout(() => {
            crossingOpen = false;
            // console.log('[staSel] crossing closing')
        }, (travelTime+closingDelta)/timeFactor*1000)
        setTimeout(() => {
            crossingOpen = true;
            // console.log('[staSel] crossing opening')
        }, (travelTime+openingDelta)/timeFactor*1000)
        setCrossingTimes();
    }

    const setCrossingTimes = async () => {
        const form = new FormData();
        form.append('file-extension', 'json');
        form.append('file-name', 'crossing_times');
        form.append('data', JSON.stringify(crossingTimes));
        await fetch(protocol + '://' + window.location.hostname + ':' + port + subPath + '/set_file.php', {
                method: 'POST',
                body: form,
                credentials: 'include'
            })
            .then(response => response.text())
            .then(data => console.log('[setCrossingTimes] POST: ' + data))
    }

    return (
        <div className='App relative text-black font-montserrat min-h-screen'>
            <div className='md:hidden p-8'>
                <LogoHeading title='Simulation' />
                <p className='mt-12'>Not available for mobile devices.</p>
            </div>
            <header className='absolute hidden md:flex flex-row justify-between items-center w-screen p-8 pointer-events-none'>
                <div className='bg-white rounded-xl p-8'>
                    <LogoHeading title='Simulation' />
                </div>
                <div className='flex flex-row space-x-4'>
                    <button key='btn_timefactor' className='bg-grey-400 text-white shadow-lg h-20 aspect-square rounded-full pointer-events-auto' onClick={toggleTimeFactor}>
                        <FontAwesomeIcon className='text-4xl' icon={faStopwatch} />
                    </button>
                    <button key='btn_fire' className='bg-red-600 text-white shadow-lg h-20 aspect-square rounded-full pointer-events-auto' onClick={fire}>
                        <FontAwesomeIcon className='text-4xl' icon={faFire} />
                    </button>
                </div>
            </header>
            <main className=' hidden md:flex flex-col justify-center h-screen'>
                <img ref={map} src={img_map} alt='' className='hidden' />
                <img ref={fw_car} src={img_fw_car} alt='' className='hidden' />
                <img ref={fw_car_ghost} src={img_fw_car_ghost} alt='' className='hidden' />
                <img ref={train} src={img_train} alt='' className='hidden' />
                <img ref={station} src={img_station} alt='' className='hidden' />
                <img ref={crossing_green} src={img_crossing_green} alt='' className='hidden' />
                <img ref={crossing_red} src={img_crossing_red} alt='' className='hidden' />
                <img ref={bridge} src={img_bridge} alt='' className='hidden' />
                <canvas ref={canvasMap} className='w-screen' onClick={canvasMapClick} width={1920} height={1080} />
                { /* Route creation display
                <div className='bg-gray-500 grid grid-cols-3 gap-4 p-8'>
                    <span>X</span>
                    <span>Y</span>
                    <span className='dummy'></span>
                    {
                        points.map((point, i) =>
                            <>
                                <span key={'point_x'+i}>{point.x}</span>
                                <span key={'point_y'+i}>{point.y}</span>
                                <button key={'point_del'+i}
                                      className='text-red-600 underline w-72'
                                      onClick={()=>{ let newPoints = [...points]; newPoints.splice(i, 1); setPoints(newPoints) }}
                                >delete</button>
                            </>
                        )
                    }
                </div>
                */}
            </main>
            <footer className='absolute bottom-0 z-20 hidden md:flex flex-row justify-between items-end w-screen p-8 pointer-events-none'>
                <div className='space-y-4 bg-white shadow-lg text-black text-2xl border-black border-2 p-6 rounded-xl pointer-events-auto'>
                    <h2 className='flex flex-row justify-between items-center font-semibold'>
                        <FontAwesomeIcon icon={faTrain} className='text-2xl' />
                        <span>Station wählen</span> {/* TODO: change direction */}
                        <FontAwesomeIcon icon={faTrain} className='text-2xl invisible' />
                    </h2>
                    <form className='relative flex flex-col' onSubmit={staSel}>
                        <div className='relative'>
                            <canvas ref={canvasTrainProgress} className='absolute left-2.5 bg-black h-full w-1' width={1} height={100} />
                            {
                                stations.map((station, s) =>
                                    <span key={'station_sel' + s} className='flex flex-row items-center border-l-4 border-black ml-2.5'>
                                        <input type='radio' id={'station_radio' + s} name='station' value={s} disabled={s===3 ? true : false} className={`-translate-x-2.5 text-red-500 checked:ring-red-500 checked:ring-2 checked:ring-offset-2 border-2 border-black focus:outline-red-500 cursor-pointer ${s===3 ? 'bg-blue-500 border-white ring-blue-500 ring-2' : ''}`} />
                                        <label htmlFor={'station_radio' + s} className='pl-4 cursor-pointer'>{station.name}</label>
                                    </span>
                                )
                            }
                        </div>
                        <button className='bg-green-600 text-white rounded-lg mt-6 p-2'>Zug starten!</button>
                    </form>
                </div>
                {/* Route craetion controlls
                <div className='flex flex-row gap-x-6'>
                    <button className='bg-grey-500 shadow-lg h-20 aspect-square rounded-full pointer-events-auto'
                            onClick={()=>{ let newPoints = [...points]; newPoints.pop(); setPoints(newPoints) }}
                    >
                        <FontAwesomeIcon className='text-4xl' icon={faRotateLeft} />
                    </button>
                    <button className='bg-grey-500 shadow-lg h-20 aspect-square rounded-full pointer-events-auto'
                            onClick={()=>{ navigator.clipboard.writeText(JSON.stringify(points)) }}
                    >
                        <FontAwesomeIcon className='text-4xl' icon={faCopy} />
                    </button>

                </div>
                */}
            </footer>
        </div>
    );
}

export default App;
