class Vehicle {
    constructor(figureImg, pointArray, vel, timeFactor, startIn) { // vel (in km/h)
        this.figureImg = figureImg;
        this.timeFactor = timeFactor;

        let vMax = 1.7955 * 60 * 3.6 // v (km/h) = s (1px in m) * f (1/s) =  107.73 m/s = 387.83 km/h
        let translation = vMax / (vel * timeFactor)
        // let vPx = 0.56 * 60 // v (px/s) = s (1m in px) * f (1/s) = 33.6 px/s
        // let vPxC = vPx / translation
        this.velRatio = translation
        // console.log(vPx, vPxC, translation)

        this.path = this.createPath(pointArray);
        /* Fire car is the negativ number. Time in seconds is matching the simulation time to the crossing
        let di = 0;
        for (let i = 0; i < this.path.length; i++) {
            if (di >= 0) di++;
            if ('c' in this.path[i]) di *= -1;
        }
        // console.log(di/60)
        */
        this.pathC = 0;
        this.isMoving = false;
        this.pos = this.path[0];
        this.angle = 0;

        setTimeout(this.start, startIn*(1000/timeFactor));
    }

    start = () => this.isMoving = true

    pause = () => this.isMoving = false

    stop = () => {
        this.isMoving = false
        this.pathC = 0
    }

    reset = () => {
        this.stop()
        this.updatePos()
    }

    move = () => {
        if (!this.isMoving) return;

        let now = Date.now() / 1000;
        if ('c' in this.path[this.pathC]) {
            if (this.path[this.pathC].c.closing < now && this.path[this.pathC].c.opening+2/this.timeFactor > now) {
                // console.log('holding for ' + (this.path[this.pathC].c.opening-now) + ' further seconds')
                return;
            }
        }

        if ('h' in this.path[this.pathC] && this.path[this.pathC].h > 0) this.path[this.pathC].h--;
        else this.pathC++;

        if (this.pathC > this.path.length-1) this.stop();
        else this.updatePos()

        //console.log(this.pos)
    }

    updatePos = () => this.pos = this.path[this.pathC]

    dist = (p1, p2) => Math.sqrt(Math.pow(p1.x-p2.x, 2)+Math.pow(p1.y-p2.y, 2))

    createPath = (pointArray) => {
        let route = [];
        for (let i = 1; i < pointArray.length; i++) {
            const s = this.dist(pointArray[i], pointArray[i - 1]);
            const d = s * this.velRatio;
            for (let j = 0; j < d; j++) {
                let tempPoint = {}
                tempPoint.y = pointArray[i - 1].y + ((pointArray[i].y - pointArray[i - 1].y) / d) * j;
                tempPoint.x = pointArray[i - 1].x + ((pointArray[i].x - pointArray[i - 1].x) / d) * j;
                tempPoint = (j === 0 && 'h' in pointArray[i-1]) ? // key 'h' stands for hold (in secs)
                    { ...tempPoint, h: pointArray[i-1].h / this.timeFactor }
                    : tempPoint
                tempPoint = (j === 0 && 'c' in pointArray[i-1]) ? // key 'c' stands for crossing
                    { ...tempPoint, c: pointArray[i-1].c }
                    : tempPoint
                route.push(tempPoint)
                // if ('h' in tempPoint) console.log(tempPoint)
            }
        }
        // console.log(route)
        return route;
    }

    reversePath = () => this.path = this.path.reverse();

    setFigure = (figureImg) => this.figureImg = figureImg;

    setPath = (pointArray) => this.path = this.createPath(pointArray);

    getAngle = () => {
        if (this.pathC>0) {
            const dx = this.path[this.pathC].x - this.path[this.pathC-1].x
            const dy = this.path[this.pathC].y - this.path[this.pathC-1].y
            this.angle = Math.atan(dy/dx);
            if (dx < 0) this.angle += Math.PI;
            else if (dy < 0) this.angle += Math.PI*2;
        }
        return this.angle
    }
}

export default Vehicle;