import logo from './images/logo.svg'

function LogoHeading(props) {
    return (
        <div className='flex flex-row items-center'>
            <img src={logo} className='w-28 mr-4' />
            <div className='flex flex-col items-start text-black'>
                <h1 className='text-2xl font-semibold'>Terminal Demo</h1>
                <h2 className='text-xl'>{props.title}</h2>
            </div>
        </div>
    );
}

export default LogoHeading;
